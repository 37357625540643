import React from 'react'
import { graphql } from 'gatsby'

import MarketplaceLayout from '../components/marketplace/MarketplaceLayout'
import Solutions from '../components/marketplace/solutions'

export default class SolutionsPage extends React.Component {
  render() {
    // return isSignedIn() ? <LoggedIn /> : <LoggedOut />
    // const { data } = this.props;
    // const DIYSurveyImage = data.wideImages.childImageSharp.fluid;
    const { data } = this.props
    const { frontmatter } = data.markdownRemark
    return (
      <MarketplaceLayout active="solutions">
        <Solutions {...frontmatter} />
      </MarketplaceLayout>
    )
  }
}

export const solutionsPageQuery = graphql`
  query SolutionsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        subtitle
        solutions {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, maxHeight: 683, quality: 80, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
          description
          long
        }
      }
    }
  }
`
