import React from 'react'
import ProductCard from '../common/ProductCard'
import Testimonial from '../common/Testimonial'
import SectionHeader from '../common/SectionHeader'
import Partners from '../common/Partners'

export default ({ title, subtitle, solutions, toggleHubspotModalSolutions, preview }) => (
  <div className="productContainer">
    <div className="mainContent">
      <SectionHeader title={title} subtitle={subtitle} />
      <div className="columns is-multiline is-variable is-6-tablet is-0-mobile topMargin">
        {solutions.map(({ title, description, image, long }) => (
          <ProductCard
            title={title}
            details={description}
            price={150}
            buttonText="Get Started"
            previewImage={{ image, alt: title }}
            key={title}
            long={long}
            onClick={toggleHubspotModalSolutions}
            section="solutions"
          />
        ))}
      </div>
    </div>
    {!preview && <Testimonial />}
    {!preview && <Partners />}
  </div>
)
