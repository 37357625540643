import React from 'react'

import { connect } from 'react-redux'
import { toggleHubspotModalSolutions } from '../../../redux/actions/modals'
import SolutionsTemplate from './Template'

class Solutions extends React.Component {
  render() {
    return <SolutionsTemplate {...this.props} />
  }
}

export default connect(() => ({}), {
  toggleHubspotModalSolutions,
})(Solutions)
